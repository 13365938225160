<template>
  <div class="work-firm">
    <div class="work-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`@/assets/image/work.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">AI求职</div>
      </div>
      <el-image
        class="head-right"
        @click="sendExit"
        :src="require(`@/assets/image/exit2.png`)"
        fit="contain"
      ></el-image>
    </div>
    <div class="work-option">
      <div
        class="option-list"
        @click="selectChat(1)"
        :class="{ active: option_id === 1 }"
      >
        职位管理
      </div>
      <div
        class="option-list"
        @click="selectChat(2)"
        :class="{ active: option_id === 2 }"
      >
        推荐候选人
      </div>
      <div
        class="option-list"
        @click="selectChat(3)"
        :class="{ active: option_id === 3 }"
      >
        沟通
      </div>
    </div>
    <div class="work-body">
      <router-view @cutTags="cutTags" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option_id: null,
    };
  },
  methods: {
    cutTags(value) {
      this.option_id = value.option_id;
    },
    sendExit() {
      this.$emit("update-value");
    },
    selectChat(index) {
      this.option_id = index;
      switch (index) {
        case 1:
          if (this.$route.path === "/workFirm/firmManagement") return;
          this.$router.push("/workFirm/firmManagement");
          break;
        case 2:
          if (this.$route.path === "/workFirm/firmRecommend") return;
          this.$router.push("/workFirm/firmRecommend");
          break;
        case 3:
          if (this.$route.path === "/workFirm/firmChat") return;
          this.$router.push("/workFirm/firmChat");
          break;
      }
    },
  },
  created() {
    if (this.$route.path === "/workFirm/firmManagement") {
      this.option_id = 1;
    } else if (this.$route.path === "/workFirm/firmRecommend") {
      this.option_id = 2;
    } else if (this.$route.path === "/workFirm/firmChat") {
      this.option_id = 3;
    } else if (this.$route.path === "/workFirm/firmManagement/addManagement") {
      this.option_id = 4;
    }
  },
};
</script>

<style lang="less" scoped>
.work-firm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .work-option {
    display: flex;
    height: 40px;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
    font-weight: bold;
    .option-list {
      cursor: pointer;
      padding: 10px 30px;
      font-size: 14px;
    }
    .active {
      background-color: #fff;
      color: #2a6ee9;
    }
  }
  .work-body {
    // 动态调整高度
    height: calc(92% - 60px);
  }
  .work-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>
